import $ from 'jquery'
import { attachDynamicEvent, whenSelectorPresent } from '~/common/helpers/dom'

let validatorSelector =
  '#cart-checkout-form input[type=email]:not(.skip-bouncer-validation)'
async function initializeValidator() {
  const { validateEmail } = await import('./validation.js')
  attachDynamicEvent(validatorSelector, 'change', validateEmail)
}

whenSelectorPresent(validatorSelector).then(initializeValidator)

const purchaseAutocorrectorSelector = '#cart-checkout-form input[type=email]'
async function initializePurchaseAutocorrector() {
  await import('./autocorrect')
  $(document).initializeEmailAutoCorrector({
    inputSelector: purchaseAutocorrectorSelector,
    correctionTextsDataContainerSelector: '#cart-checkout-form',
    autoCorrectOnSubmit: true,
  })
}

whenSelectorPresent(purchaseAutocorrectorSelector).then(
  initializePurchaseAutocorrector
)

const listAutocorrectorSelector = '.signup-form__field input[type=email]'
async function initializeListAutocorrector() {
  await import('./autocorrect')
  $(document).initializeEmailAutoCorrector({
    inputSelector: listAutocorrectorSelector,
    correctionTextsDataContainerSelector: '.signup-form',
    autoCorrectOnSubmit: true,
  })
}

whenSelectorPresent(listAutocorrectorSelector).then(initializeListAutocorrector)
